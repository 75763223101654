<template>
	<div id="app" :class="'rtl ' + (show_side_menu ? 'menu_open' : '')">
		<!-- <div id="side_menu" v-if="loaded && !show_login">
			<div v-if="!is_mobile()">
				<div id="menu_logo"></div>
				<ul>
					<li v-for="menu_item in menu" :key="menu_item.id">
						<router-link
							v-on:click="show_side_menu = false"
							:to="menu_item.link"
							>{{ menu_item.name }}</router-link
						>
					</li>
				</ul>
			</div>
			<div class="mobile" v-else>
				<div id="hamburger" @click="menuActive = !menuActive">
					<i class="fa-solid fa-bars"></i>
				</div>
				<div id="menu_logo"></div>
			</div>
			<div class="mobile_menu">
				<ul v-if="menuActive">
					<li v-for="menu_item in menu" :key="menu_item.id">
						<router-link
							v-on:click="show_side_menu = false"
							:to="menu_item.link"
							@click="menuActive = !menuActive"
							>{{ menu_item.name }}</router-link
						>
					</li>
				</ul>
			</div>
		</div> -->

		<main id="page" v-if="loaded">
				<router-view
					v-slot="{ Component }"
					@loaded="is_show_loader = false"
					@show_loader="is_show_loader = true"
				>
					<transition name="fade">
						<component :is="Component" :key="$route.path" />
					</transition>
				</router-view>

		</main>
	</div>
</template>

<script>

export default {
	name: "app",
	data() {
		return {
			loaded: false,
			menuActive: false,
			menu: [
			],
			header_class: "",
			show_side_menu: false,
			user: false,
		};
	},
	mounted() {
		window.appVue = this;
		this.$bus.$on("reload", (loadData, openURL) => {
			this.reload(loadData, openURL);
		});
		this.reloadPage();

		this.$bus.$on("close_menu", this.closeMenu);
		this.$bus.$on("close_menu", this.changeTheme);
	},
	methods: {
		reload(loadData, openURL) {
			this.loaded = false;

			if (this.isNull(openURL)) openURL = "/";

			if (this.$route.path !== "/") this.$router.push(openURL);
			//this.refreshKey++;
			if (loadData !== false) this.reloadPage();
			else this.loaded = true;
		},
		closeMenu() {
			this.show_side_menu = false;
		},
		handleMenuToggle() {
			this.show_side_menu = !this.show_side_menu;
		},

		reloadPage() {
			let getVars = {};
			let uri = window.location.href.split("?");
			if (uri.length == 2) {
				let vars = uri[1].split("&");

				let tmp = "";
				vars.forEach(function (v) {
					tmp = v.split("=");
					if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
				});
				// do
			}

			if (!this.isNull(getVars.token)) {
				this.set_user_token(getVars.token);
				document.location = "/";
			}

			// const platformId = +localStorage.getItem("langId") || 1;
			// if (platformId && platformId != 1) document.body.classList.add("ltr");
			// else document.body.classList.remove("ltr");
			// categories
			this.api(
				{ action: "general" },
				(data) => {
					this.$store.state.words = data.data.words;

					this.loaded = true;
				}
			);
		},
	},
};
</script>